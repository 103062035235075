.dashboardPage {
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
  padding: 2rem;
  min-height: calc(100vh - 64px);
  color: var(--text-primary);
  background-color: var(--bg-primary);
  display: flex;
  flex-direction: column;
  align-items: center;

  .title {
    font-size: clamp(28px, 5vw, 36px);
    margin-bottom: 3rem;
    font-weight: 700;
    color: var(--text-primary);
    text-align: center;
  }

  .widgets {
    display: grid;
    width: 100%;
    gap: 1.5rem;
    justify-content: center;

    // Mobile: 1 column
    grid-template-columns: minmax(auto, 340px);

    // Tablet: 2 columns
    @media (min-width: 768px) {
      grid-template-columns: repeat(2, 340px);
    }

    // Desktop: 3 columns
    @media (min-width: 1200px) {
      grid-template-columns: repeat(3, 340px);
    }

    :global(.buttonWidget) {
      height: 280px;
      width: 100%;

      .route {
        background-color: var(--bg-secondary);
        border-radius: var(--border-radius);
        padding: 2.5rem;
        height: 100%;
        transition: all var(--transition-speed) ease;
        cursor: pointer;
        color: var(--text-primary);
        border: 1px solid var(--border-color);
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        text-decoration: none;

        &:hover {
          background-color: #3f495e;
          border-color: var(--accent-color);
          box-shadow: var(--hover-shadow);
          transform: translateY(-4px);

          .icon {
            color: var(--accent-light);
          }
        }

        .icon {
          font-size: 3rem;
          color: var(--accent-color);
          margin-bottom: 1.75rem;
          transition: all var(--transition-speed) ease;
        }

        .textBox {
          width: 100%;

          .title {
            font-size: 1.375rem;
            font-weight: 600;
            margin: 0 0 1.25rem 0;
            color: var(--text-primary);
            text-align: left;
          }
        }

        .digit {
          font-size: 2.5rem;
          font-weight: 700;
          color: var(--accent-light);
          margin-top: auto;
        }
      }
    }
  }
}
