.allUsersPage {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem 1.5rem 100px;
  background-color: #1a1a1a;
  min-height: 100vh;

  .title {
    font-size: 32px;
    font-weight: 600;
    color: #e2e8f0;
    margin-bottom: 2rem;
  }

  .searchField {
    width: 100%;
    max-width: 400px;
    font-size: 16px;
    color: #e2e8f0;
    border: 2px solid #2d3748;
    background-color: #2d3748;
    border-radius: 8px;
    padding: 0.75rem 1rem;
    margin-bottom: 2rem;
    transition: all 0.2s ease-in-out;

    &:focus {
      outline: none;
      border-color: #61dafb;
      box-shadow: 0 0 0 3px rgba(97, 218, 251, 0.2);
    }

    &::placeholder {
      color: #718096;
    }
  }

  .userBox {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 1.5rem;
    width: 100%;
    margin: 0 auto 2rem;

    .userWrapper {
      width: 100%;
      padding: 1.5rem;
      box-sizing: border-box;
      background: #2d3748;
      border-radius: 12px;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
      transition: transform 0.2s ease-in-out;
      color: #e2e8f0;

      &:hover {
        transform: translateY(-2px);
        background: #3a4a63;
      }
    }
  }

  .loading-spinner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 400px;

    .spinner {
      width: 50px;
      height: 50px;
      border: 4px solid #2d3748;
      border-top: 4px solid #61dafb;
      border-radius: 50%;
      animation: spin 1s linear infinite;
      margin-bottom: 1rem;
    }

    p {
      color: #a0aec0;
      font-size: 16px;
    }
  }

  .no-results {
    text-align: center;
    color: #a0aec0;
    font-size: 16px;
    margin: 2rem 0;
  }

  button {
    display: block;
    margin: 2rem auto 0;
    padding: 0.75rem 1.5rem;
    font-size: 16px;
    font-weight: 500;
    background-color: #61dafb;
    color: #1a202c;
    border-radius: 8px;
    border: none;
    cursor: pointer;
    transition: all 0.2s ease-in-out;

    &:hover {
      background-color: #4fa8d5;
      transform: translateY(-1px);
    }

    &:active {
      transform: translateY(0);
    }

    &:disabled {
      background-color: #2d3748;
      color: #718096;
      cursor: not-allowed;
      transform: none;
    }
  }

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
}