.container {
  padding: 20px;

  .error {
    color: #dc3545;
    text-align: center;
    margin-top: 2rem;
  }

  .no-results {
    text-align: center;
    color: #666;
    margin-top: 2rem;
    font-size: 1.1rem;
  }

  .searchField {
    font-size: 18px;
    color: #c9c9c9;
    border: 1px solid #61dafb;
    background-color: #ffffff26;
    border-radius: 3px;
    padding: 0.5rem;
  }

  .appList {
    width: 50%;
    margin: 20px auto;
    flex-direction: row;
    flex-wrap: wrap;
    > div {
      width: 50%;
    }
  }
}
