.registerPage {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin: 0.5rem;

  .title {
    font-size: 22px;
    margin: 0;
    padding-bottom: 0.25rem;  
  }

  .login {
    font-size: 14px;

    .link {
      text-decoration: none;
      color: #61dafb;
    }
  }
}