@mixin devices($breakpoint) {
  @if $breakpoint ==smallLaptop {
    @media only screen and (max-width: 992px) {
      @content;
    }
  }

  @if $breakpoint ==tablet {
    @media only screen and (max-width: 680px) {
      @content;
    }
  }

  @if $breakpoint ==mobile {
    @media only screen and (max-width: 481px) {
      @content;
    }
  }
}
