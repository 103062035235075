.buttonWidget {
  display: flex;
  border: 1px solid #000;
  border-radius: 5px;
  background-color: #00000045;
  width: 250px;
  margin: 1rem;

  .route {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    text-decoration: none;
    padding: 0.75rem;
    color: #fff;
    width: 100%;

    .icon {
      width: 30px;
      height: 30px;
      color: #fff;
      path {
        stroke: #fff;
      }
    }

    .textBox {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      text-align: left;
      margin-left: 1rem;
      width: 70%;

      .title {
        margin: 0;
        font-size: 18px;
        text-align: left;
      }

      .description {
        margin: 0;
        font-size: 14px;
        padding-top: 5px;
      }
    }
  }
}
