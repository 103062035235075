.passwordInputField {
  display: flex;
  flex-direction: column;
  width: 100%;
  text-align: left;

  .label {
    display: block;
    font-size: clamp(13px, 2.5vw, 14px);
    line-height: 1.4;
    font-weight: 500;
    color: var(--text-primary);
    margin-bottom: 4px;
    transition: color 0.3s ease;
  }

  .fieldBox {
    display: flex;
    align-items: center;
    width: 100%;
    height: 44px;
    background: var(--input-background);
    border: 1px solid var(--border-color);
    border-radius: 8px;
    transition: all 0.3s ease;

    &:hover {
      border-color: var(--text-secondary);
    }

    &:focus-within {
      border-color: var(--text-primary);
      box-shadow: 0 0 0 3px var(--focus-ring);
    }

    .input {
      flex: 1;
      height: 100%;
      padding: 0 16px;
      border: none;
      background: transparent;
      font-size: 15px;
      line-height: 24px;
      color: var(--text-primary);

      &::placeholder {
        color: var(--text-secondary);
      }

      &:focus {
        outline: none;
      }
    }

    .icon {
      flex: none;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 44px;
      height: 44px;
      padding: 0;
      margin-right: 4px;
      background: none;
      border: none;
      color: var(--text-secondary);
      cursor: pointer;
      transition: color 0.2s ease;

      &:hover {
        color: var(--text-primary);
      }

      svg {
        width: 20px;
        height: 20px;
      }
    }
  }
}
