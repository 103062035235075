.customAttributesWrapper {
  margin-top: 0.5rem;
  width: 100%;

  .cATitle {
    font-size: 14px;
    margin-bottom: 0.5rem;
  }
  
  .attributeWrapper {
    text-align: left;
    
    .attributeBox {
      border-radius: 3px;
      border: 1px solid #60dafb;
      align-items: center;
      display: flex;
      position: relative;

      .attributeName {
        color: #60dafb;
        font-weight: 500;
        border-right: 1px solid #60dafb;
        padding: 10px;
        width: 70px;
        min-width: 70px;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      .attributeValue {
        color: #fff;
        padding: 10px;
        text-overflow: ellipsis;
        overflow: hidden;
        width: 220px;
        max-width: 220px;
      }

      .deleteAttribute {
        position: absolute;
        right: 5px;
        width: 20px;
        height: 20px;
        color: #60dafb;
        background-color: #282c34;
        cursor: pointer;
      }
    }
  }

  .newAttributeBox {
  }
  
  .addIcon {
    margin-top: 0.5rem;
    cursor: pointer;
  }
}