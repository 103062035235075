// Modern dark theme variables with purple accents
:root {
  --bg-primary: #0f172a;
  --bg-secondary: #1e293b;
  --bg-tertiary: #334155;
  --text-primary: #f8fafc;
  --text-secondary: #94a3b8;
  --accent-color: #6366f1;
  --accent-light: #818cf8;
  --accent-dark: #4f46e5;
  --success-color: #22c55e;
  --error-color: #ef4444;
  --border-color: #334155;
  --card-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.2), 0 2px 4px -2px rgb(0 0 0 / 0.2);
  --hover-shadow: 0 10px 15px -3px rgb(99 102 241 / 0.25), 0 4px 6px -4px rgb(99 102 241 / 0.25);
  --border-radius: 12px;
  --transition-speed: 0.2s;
}

.allAppsPage {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem 1rem;
  min-height: 100vh;
  color: var(--text-primary);
  background-color: var(--bg-primary);

  .title {
    font-size: clamp(24px, 5vw, 32px);
    margin-bottom: 2rem;
    font-weight: 600;
    color: var(--text-primary);
    text-align: center;
    padding: 0 1rem;

    @media (min-width: 768px) {
      padding: 0;
      text-align: center;
    }
  }

  .searchWrapper {
    position: relative;
    width: min(280px, 90%);
    margin: 0 auto 2rem;
    display: flex;
    justify-content: center;

    @media (min-width: 768px) {
      width: min(500px, 90%);
      margin-bottom: 3rem;
    }

    .searchField {
      width: 100%;
      font-size: 16px;
      color: var(--text-primary);
      background-color: var(--bg-secondary);
      border: 1px solid var(--border-color);
      border-radius: var(--border-radius);
      padding: 0.875rem 2.5rem 0.875rem 1rem;
      transition: all var(--transition-speed) ease;

      &::placeholder {
        color: var(--text-secondary);
      }

      &:focus {
        outline: none;
        border-color: var(--accent-color);
        box-shadow: 0 0 0 3px rgba(99, 102, 241, 0.2);
        background-color: var(--bg-tertiary);
      }
    }

    .clearSearch {
      position: absolute;
      right: 0.25rem;
      top: 50%;
      transform: translateY(-50%);
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0.25rem;
      color: var(--text-secondary);
      background: none;
      border: none;
      border-radius: 6px;
      cursor: pointer;
      transition: all var(--transition-speed) ease;

      &:hover {
        color: var(--text-primary);
        background-color: var(--bg-tertiary);
      }

      &:focus {
        outline: none;
        box-shadow: 0 0 0 2px var(--accent-color);
      }
    }
  }

  .appBox {
    display: flex;
    width: 100%;
    margin: 0 auto;

    .appList {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
      gap: 1.5rem;
      padding: 0 1rem;

      @media (min-width: 768px) {
        gap: 2rem;
        padding: 0;
      }

      .appItem {
        background-color: var(--bg-secondary);
        border-radius: var(--border-radius);
        padding: 1.25rem;
        transition: transform var(--transition-speed) ease,
          background-color var(--transition-speed) ease;
        cursor: pointer;
        text-align: left;

        &:hover {
          // transform: translateY(-2px);
          background-color: var(--bg-tertiary);
        }

        .appName {
          font-size: 1.125rem;
          font-weight: 500;
          color: var(--text-primary);
          margin-bottom: 0.5rem;
          text-align: left;
        }
      }
    }
  }

  .cta {
    display: block;
    width: 100%;
    max-width: 200px;
    margin: 2rem auto;
    padding: 0.75rem 1.5rem;
    font-size: 16px;
    font-weight: 500;
    color: var(--text-primary);
    background: linear-gradient(135deg, var(--accent-color), var(--accent-light));
    border: none;
    border-radius: var(--border-radius);
    cursor: pointer;
    transition: all var(--transition-speed) ease;

    &:hover {
      transform: translateY(-2px);
      background: linear-gradient(135deg, var(--accent-dark), var(--accent-color));
      box-shadow: var(--hover-shadow);
    }

    &:focus {
      outline: none;
      box-shadow: 0 0 0 3px rgba(99, 102, 241, 0.3);
    }

    &:active {
      transform: translateY(0);
    }

    &:disabled {
      opacity: 0.7;
      cursor: not-allowed;
      transform: none;
    }
  }

  // Loading skeleton styles
  .skeleton {
    background: linear-gradient(
      90deg,
      var(--bg-secondary) 25%,
      var(--bg-tertiary) 50%,
      var(--bg-secondary) 75%
    );
    background-size: 200% 100%;
    animation: loading 1.5s infinite;
    border-radius: var(--border-radius);
    height: 24px;
    margin-bottom: 1rem;
  }
}

@keyframes loading {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}
