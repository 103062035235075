.listModal {
  display: flex;
  flex-direction: column;
  align-items: center;

  .textWrapper {
    width: 100%;
    text-align: left;

    .title {
      font-size: 18px;
      margin: 0;
      margin-bottom: 0.25rem;
    }

    .description {
      font-size: 15px;
      margin: 0;
    }


  }

  .searchBox {
    display: flex;
    margin-top: 1rem;

    .searchField {
      font-size: 18px;
      color: #c9c9c9;
      border: 1px solid #61dafb;
      background-color: #ffffff26;
      border-radius: 3px;
      padding: 0.5rem;
    }

    .suggestions.active {
      top: 480px;
    }
  }

  .addNewApp {
    font-size: 13px;
    margin: 5px 0;
    .link {
      text-decoration: none;
      color: #61dafb;
    }
  }
}
