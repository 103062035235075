.allAppsPage {
  width: 100%;
  margin-bottom: 2rem;

  .title {
    font-size: 28px;
  }

  .searchField {
    font-size: 18px;
    color: #c9c9c9;
    border: 1px solid #61dafb;
    background-color: #ffffff26;
    border-radius: 3px;
    padding: 0.5rem;
  }

  .appBox {
    display: flex;
    width: 100%;
    max-width: 900px;
    margin: 0 auto;

    .appList {
      max-height: initial;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
    }
  }

}