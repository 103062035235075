.button {
  margin: 0.25rem;
  cursor: pointer;
  font-size: 14px;
  color: #c9c9c9;
  border-radius: 5px;
  padding: 0.5rem 0.75rem;
  background-color: #282c34;
  border: 1px solid #7487ff;

  &:disabled,
  &[disabled] {
    color: #858585;
    border: 1px solid #646eab;
  }
}