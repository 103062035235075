.subscriptions {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding: 1rem 0.5rem;
  min-height: 100vh;
  color: var(--text-primary);
  background-color: var(--bg-primary);
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: 768px) {
    padding: 2rem 1rem;
  }
}

.title {
  font-size: clamp(20px, 4vw, 32px);
  margin-bottom: 1.5rem;
  font-weight: 600;
  color: var(--text-primary);
  text-align: center;
  padding: 0 0.5rem;
  width: 100%;

  @media (min-width: 768px) {
    margin-bottom: 2rem;
    padding: 0;
  }
}

.subscriptionList {
  width: 100%;
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  @media (min-width: 768px) {
    padding: 1rem 2rem;
    gap: 1.5rem;
  }
}

.loadingContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem 0;

  @media (min-width: 768px) {
    padding: 4rem 0;
  }
}

.addToggle {
  margin: 0.75rem 0;
  padding: 0.5rem 1rem;
  width: calc(100% - 2rem);
  max-width: 300px;
  font-size: 1rem;
  color: var(--text-primary);
  background-color: var(--accent-color);
  border: none;
  border-radius: var(--border-radius);
  cursor: pointer;
  transition: all var(--transition-speed) ease;

  &:hover {
    background-color: var(--accent-light);
    transform: translateY(-1px);
    box-shadow: var(--hover-shadow);
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(99, 102, 241, 0.3);
  }

  @media (min-width: 768px) {
    margin: 1rem 0;
    padding: 0.75rem 1.5rem;
    width: auto;
  }
}
