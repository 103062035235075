.attributes {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem 1rem;
  min-height: 100vh;
  color: var(--text-primary);
  background-color: var(--bg-primary);

  .title {
    font-size: clamp(24px, 5vw, 32px);
    margin-bottom: 2rem;
    font-weight: 600;
    color: var(--text-primary);
    text-align: center;
    padding: 0 1rem;

    @media (min-width: 768px) {
      padding: 0;
      text-align: center;
    }
  }

  .attributeBox {
    width: min(800px, 90%);
    margin: 0 auto 2rem;
    display: flex;
    align-items: center;
    flex-direction: column;
    background-color: var(--bg-secondary);
    border-radius: var(--border-radius);
    padding: 1.5rem;
    box-shadow: var(--card-shadow);
    transition: box-shadow var(--transition-speed) ease;

    @media (min-width: 768px) {
      padding: 2rem;
      margin-bottom: 3rem;
    }
  }

  .skeleton {
    height: 24px;
    background: var(--bg-secondary);
    border-radius: var(--border-radius);
    margin: 1rem auto;
    animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
  }
}

@keyframes pulse {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}
