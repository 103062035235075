.badge {
  display: flex;
  border: 1px solid #13b958;
  padding: 5px 10px;
  border-radius: 5px;

  .text {
    color: #fff;
    font-size: 12px;
    text-transform: uppercase;
  }
}
