.inputField {
  display: flex;
  flex-direction: column;
  width: 100%;
  text-align: left;

  .label {
    display: block;
    font-size: clamp(13px, 2.5vw, 14px);
    line-height: 1.4;
    font-weight: 500;
    color: var(--text-primary);
    margin-bottom: 4px;
    transition: color 0.3s ease;
  }

  .input {
    width: 100%;
    height: 44px;
    padding: 0 16px;
    background: var(--input-background);
    border: 1px solid var(--border-color);
    border-radius: 8px;
    font-size: 15px;
    line-height: 24px;
    color: var(--text-primary);
    transition: all 0.3s ease;

    &::placeholder {
      color: var(--text-secondary);
    }

    &:hover {
      border-color: var(--text-secondary);
    }

    &:focus {
      outline: none;
      border-color: var(--text-primary);
      box-shadow: 0 0 0 3px var(--focus-ring);
    }
  }
}
