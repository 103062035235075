.publicLists {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  
  .AppLogo {
    height: 10rem;
    pointer-events: none;
    
    @media (prefers-reduced-motion: no-preference) {
      animation: App-logo-spin infinite 20s linear;
    }
  }
  
  .title {
    font-size: 24px;
  }
}