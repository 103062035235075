.pendingAppChangePage {
  display: flex;
  flex-wrap: wrap;
  max-width: 1600px;
  margin: 0 auto;
  padding: 2rem;
  gap: 2rem;

  h2 {
    font-size: 1.5rem;
    font-weight: 600;
    color: #e2e8f0;
    margin-bottom: 1.5rem;
    width: 100%;
    text-align: center;
  }

  .appContainer,
  .appChangeContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem;
    box-sizing: border-box;
    background: #1a202c;
    border-radius: 12px;
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.2);
    transition: transform 0.2s ease;
    border: 1px solid #2d3748;

    &:hover {
      border-color: #4a5568;
    }

    @media screen and (min-width: 768px) {
      width: calc(50% - 1rem);
    }

    > * {
      margin: 0.75rem 0;
      width: 100%;
    }
  }

  .appImage {
    width: 64px;
    height: 64px;
    margin: 1rem 0 1.5rem;
    border-radius: 12px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
    object-fit: cover;
  }

  .title {
    font-size: 1.5rem;
    font-weight: 600;
    color: #e2e8f0;
    margin: 0;
    padding-bottom: 1rem;
    text-align: center;
    border-bottom: 1px solid #2d3748;
  }

  .description {
    max-width: 100%;
    font-size: 0.95rem;
    line-height: 1.6;
    color: #a0aec0;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    margin: 1rem 0;
  }

  p {
    font-size: 0.95rem;
    line-height: 1.6;
    color: #a0aec0;
    margin: 0.5rem 0;

    &.price {
      font-weight: 600;
      color: #e2e8f0;
      font-size: 1.1rem;
    }

    &.random {
      display: flex;
      align-items: center;
      padding: 0.5rem 0;
      border-bottom: 1px solid #2d3748;

      &:last-child {
        border-bottom: none;
      }
    }
  }

  .screenshots {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    gap: 1rem;
    width: 100%;
    margin: 1.5rem 0;

    img {
      width: 100%;
      height: 100px;
      object-fit: cover;
      border-radius: 8px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
      transition: transform 0.2s ease;

      &:hover {
        transform: scale(1.05);
      }
    }
  }

  .highlight-change {
    background-color: rgba(255, 255, 0, 0.1);
    padding: 0.5rem 1rem;
    border-radius: 6px;
    border-left: 3px solid #ecc94b;
  }
}

.pendingAppChangePage__buttonContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 1rem;
  padding: 2rem 0;

  button.cta {
    padding: 0.75rem 2rem;
    font-size: 1rem;
    font-weight: 600;
    border-radius: 8px;
    border: none;
    cursor: pointer;
    transition: all 0.2s ease;

    &:first-child {
      background-color: #2f855a;
      color: #e2e8f0;

      &:hover {
        background-color: #276749;
      }
    }

    &:last-child {
      background-color: #c53030;
      color: #e2e8f0;

      &:hover {
        background-color: #9b2c2c;
      }
    }
  }
}
