.newAttribute {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 400px;
  margin: 0 auto;

  .newAttributeBox {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 1.25rem;
    padding: 1.5rem;
    background-color: var(--bg-secondary);
    border-radius: var(--border-radius);
    border: 1px solid var(--border-color);
    box-sizing: border-box;

    input[type="text"] {
      width: 100%;
      font-size: 16px;
      color: var(--text-primary);
      background-color: var(--bg-tertiary);
      border: 1px solid var(--border-color);
      border-radius: var(--border-radius);
      padding: 0.875rem 1rem;
      transition: all var(--transition-speed) ease;
      box-sizing: border-box;

      &::placeholder {
        color: var(--text-secondary);
      }

      &:focus {
        outline: none;
        border-color: var(--accent-color);
        box-shadow: 0 0 0 3px rgba(99, 102, 241, 0.2);
      }
    }

    label {
      color: var(--text-secondary);
      font-size: 14px;
      margin-bottom: 0.5rem;
    }

    input[type="checkbox"] {
      accent-color: var(--accent-color);
      width: 16px;
      height: 16px;
      margin-right: 0.5rem;
      cursor: pointer;
    }
  }

  .actions {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-top: 0.5rem;

    .cancel,
    .save {
      width: 100%;
      box-sizing: border-box;
      margin: 0;
      padding: 0.75rem 1rem;
      font-size: 14px;
      font-weight: 500;
      border-radius: var(--border-radius);
      transition: all var(--transition-speed) ease;
      cursor: pointer;
    }

    .cancel {
      background-color: var(--bg-tertiary);
      color: var(--text-primary);
      border: 1px solid var(--border-color);

      &:hover {
        background-color: var(--bg-secondary);
      }
    }

    .save {
      background-color: var(--accent-color);
      color: white;
      border: none;

      &:hover {
        background-color: var(--accent-dark);
      }

      &:disabled {
        opacity: 0.7;
        cursor: not-allowed;
      }
    }
  }

  .attributeAddIcon {
    background-color: var(--bg-secondary);
    color: var(--text-primary);
    border-radius: 50%;
    padding: 0.75rem;
    margin-top: 1rem;
    cursor: pointer;
    transition: all var(--transition-speed) ease;
    border: 1px solid var(--border-color);

    &:hover {
      background-color: var(--bg-tertiary);
      color: var(--accent-color);
    }
  }
}
