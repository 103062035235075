.selectorField {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0.75rem 0;
  width: 100%;

  .label {
    color: var(--text-primary);
    font-size: 0.875rem;
    font-weight: 500;
    margin-bottom: 0.5rem;
  }

  .selector {
    width: 100%;
    
    .selector-field__control {
      min-height: 48px;
      background: var(--bg-tertiary);
      border: 1px solid var(--border-color);
      border-radius: 8px;
      transition: all 0.2s ease;
      cursor: pointer;

      &:hover {
        border-color: var(--accent-color);
      }

      &.selector-field__control--is-focused {
        border-color: var(--accent-color);
        box-shadow: 0 0 0 3px rgba(99, 102, 241, 0.1);
      }

      .selector-field__value-container {
        padding: 8px 12px;
      }

      .selector-field__single-value {
        color: var(--text-primary);
        font-size: 0.9375rem;
      }

      .selector-field__placeholder {
        color: var(--text-secondary);
        opacity: 0.7;
        font-size: 0.9375rem;
      }

      .selector-field__indicators {
        padding-right: 8px;

        .selector-field__indicator {
          padding: 8px;
          color: var(--text-secondary);

          &:hover {
            color: var(--accent-color);
          }
        }
      }
    }

    .selector-field__menu {
      margin-top: 4px;
      background: var(--bg-secondary);
      border: 1px solid var(--border-color);
      border-radius: 8px;
      box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
      z-index: 2;

      .selector-field__menu-list {
        padding: 6px;
        max-height: 300px;

        @media (max-width: 480px) {
          max-height: 250px;
        }
      }

      .selector-field__option {
        padding: 10px 12px;
        font-size: 0.9375rem;
        color: var(--text-primary);
        background: transparent;
        border-radius: 6px;
        cursor: pointer;
        transition: all 0.2s ease;

        &:active {
          background: var(--accent-color);
          color: white;
        }

        &--is-focused {
          background: var(--bg-tertiary);
        }

        &--is-selected {
          background: var(--accent-color);
          color: white;
        }
      }
    }

    @media (max-width: 480px) {
      .selector-field__control {
        min-height: 44px;
      }

      .selector-field__value-container {
        padding: 6px 10px;
      }

      .selector-field__single-value,
      .selector-field__placeholder {
        font-size: 0.875rem;
      }
    }
  }
}
