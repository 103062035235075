.attributeList {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  width: 300px;

  .attributeComponent {
    box-sizing: border-box;
    border: 1px solid #61dafb;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 3px;
    padding: 10px;
    gap: 1rem;
    width: 100%;

    .name {
      margin: 0;
    }
  }

  .attributeActions {
    display: flex;
    gap: 1rem;

    .attributeIcon {
      cursor: pointer;
    }
  }
}
