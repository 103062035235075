.miniListPreview {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #3c3c3c;
  border-radius: 5px;
  width: 400px;
  padding: 1rem;
  margin: 1rem;
  position: relative;

  .listTextBox {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;

    .listUrl {
      text-decoration: none;
      outline: none;
      text-align: left;

      .counter {
        font-size: 12px;
      }

      .title {
        color: #61dafb;
        font-size: 24px;
        margin: 0;
      }
    }

    .description {
      font-size: 14px;
      margin: 0;
    }

    .publicBadge {
      margin-left: 2rem;
    }
  }

  .listAppBox {
    width: 100%;

    .appComponent {
      margin-left: 0;
      margin-right: 0;

      .appImage {
        height: 30px;
        width: 30px;
      }
    }
  }
}
