.editAppPage {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  margin: 0.5rem;

  .appImage {
    height: 120px;
    margin-bottom: 1rem;
    border-radius: 5px;
  }

  .status {
    border: 2px solid #848484;
    border-radius: 3px;
    padding: 7px;
    margin: 0.5rem;
  }

  .title {
    font-size: 22px;
    margin: 0;
    padding-bottom: 0.25rem;
  }

  .description {
    width: 500px;
    font-size: 12px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }

  .link {
    color: aqua;
  }

  .editAppPageInputField {
    font-size: 18px;
    color: #c9c9c9;
    border: 1px solid #61dafb;
    background-color: #ffffff26;
    border-radius: 3px;
    padding: 0.5rem;
  }

  .editAppPageSellerInputField {
    @extend .editAppPageInputField;
    margin-left: 1rem;
  }

  .editAppPageTextarea {
    @extend .editAppPageInputField;
    height: 100px;
    margin-top: 1rem;
    resize: none;
  }
}