.appList {
  display: flex;
  flex-direction: column;
  overflow: auto;
  width: 100%;

  .appComponent {
    display: flex;
    align-items: center;
    text-align: left;
    padding: 1.25rem;
    border-radius: var(--border-radius);
    background-color: var(--bg-secondary);
    border: 1px solid var(--border-color);
    margin: 0.5rem;
    transition: all var(--transition-speed) ease;
    position: relative;

    &:hover {
      // transform: translateY(-2px);
      background-color: var(--bg-tertiary);
      border-color: var(--accent-color);
      box-shadow: var(--hover-shadow);
    }

    .appImage {
      height: 65px;
      width: 65px;
      object-fit: cover;
      object-position: center;
      margin-right: 1rem;
      border-radius: var(--border-radius);
    }

    .textBox {
      flex: 1;
      min-width: 0;
      margin: 0 1rem;
      text-align: left;

      .appUrl {
        color: var(--text-primary);
        text-decoration: none;
        display: block;
        text-align: left;

        &:hover {
          color: var(--accent-light);
        }
      }

      .title {
        font-size: 20px;
        font-weight: 600;
        margin: 0;
        padding-bottom: 0.5rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: var(--text-primary);
        font-size: 1.125rem;
        font-weight: 500;
        text-align: left;
      }

      .description {
        font-size: 14px;
        margin: 0;
        color: var(--text-secondary);
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        line-height: 1.4;
        font-size: 0.875rem;
        line-height: 1.5;
        text-align: left;
      }
    }

    .appDeleteIcon {
      cursor: pointer;
      height: 15px;
      width: 15px;
      padding: 8px;
      color: var(--text-secondary);
      border-radius: 8px;
      background-color: var(--bg-tertiary);
      transition: all var(--transition-speed) ease;
      margin-left: auto;
      position: absolute;
      top: 1rem;
      right: 1rem;

      &:hover {
        background-color: var(--accent-color);
        color: var(--text-primary);
        color: var(--text-primary);
      }
    }
  }
}
