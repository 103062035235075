.pendingPlatform {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;

  > .imgContainer {
    text-align: center;
    
    > img {
      width: 150px;
      height: 150px;
      border-radius: 8px;
    }
  }

  > .text {
    margin: 15px 0;
    text-align: center;
  }

  > .platformForm {
    display: flex;
    justify-content: space-between;
    padding: 12px;
    width: 50%;
    margin: 0 auto;
    align-items: center;
    border: 2px solid #000;
    border-radius: 3px;
    > * {
      flex-basis: 33.333%;
      display: block;
    }
    > .imgContainer {
      display: flex;
      justify-content: flex-start;
      > img {
        height: 100px;
      }
    }
    > .link {
      background: #e3e4e5;
      color: #000;
      border-radius: 4px;
      padding: 4px 0px;
    }
    > .approve {
      display: flex;
      justify-content: flex-end;
      gap: 30px;
      flex-grow: 1;
      > button {
        background: none;
        height: 50px;
        width: 50px;
        border: 1px solid #fff;
        border-radius: 3px;
        cursor: pointer;
        > svg {
          width: 22px;
          height: 22px;
          margin-top: 5px;
          &.check {
            color: green;
          }
          &.cross {
            color: red;
          }
        }
      }
    }
  }
}