@import '../../styles/variables.scss';

.editSubscription {
  padding: 1.5rem;
  background-color: var(--bg-secondary);
  border-radius: var(--border-radius);
  
  .header {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-bottom: 1.5rem;

    .appIcon {
      width: 48px;
      height: 48px;
      border-radius: 12px;
      object-fit: cover;
    }

    .appTitle {
      font-size: 1.125rem;
      font-weight: 600;
      color: var(--text-primary);
      margin: 0;
    }
  }

  .formSection {
    margin-bottom: 1.5rem;
    width: 100%;

    .sectionTitle {
      font-size: 0.875rem;
      font-weight: 500;
      color: var(--text-secondary);
      margin-bottom: 1rem;
      text-transform: uppercase;
      letter-spacing: 0.5px;
    }

    .billingInputs {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      width: 100%;
      max-width: 400px;

      :global(.inputField), :global(.selectorField) {
        width: 100%;
      }

      :global(.inputField) input {
        height: 44px;
      }

      :global(.select__control) {
        min-height: 44px;
        height: 44px;
      }
    }

    :global {
      .MuiOutlinedInput-root {
        background: var(--bg-tertiary);
        border-radius: 8px;
        min-height: 120px;
        transition: all 0.2s ease;

        &:hover {
          .MuiOutlinedInput-notchedOutline {
            border-color: var(--accent-color);
          }
        }

        &.Mui-focused {
          .MuiOutlinedInput-notchedOutline {
            border-color: var(--accent-color);
            border-width: 2px;
          }
        }

        .MuiOutlinedInput-input {
          color: var(--text-primary);
          font-size: 0.9375rem;
          padding: 0.75rem 1rem;
          
          &::placeholder {
            color: var(--text-secondary);
            opacity: 0.7;
          }
        }
      }

      .MuiInputLabel-root {
        color: var(--text-secondary);
        font-size: 0.875rem;
        
        &.Mui-focused {
          color: var(--accent-color);
        }
      }

      .MuiOutlinedInput-notchedOutline {
        border-color: var(--border-color);
      }
    }
  }

  .priceBox {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
    margin-bottom: 1rem;

    @media (max-width: 480px) {
      grid-template-columns: 1fr;
    }

    :global {
      .MuiFormControl-root {
        width: 100%;
      }

      .MuiOutlinedInput-root {
        background-color: var(--bg-tertiary);
        border-radius: 8px;
        transition: all var(--transition-speed) ease;

        &:hover {
          background-color: var(--bg-tertiary);

          .MuiOutlinedInput-notchedOutline {
            border-color: var(--accent-color);
          }
        }

        &.Mui-focused {
          .MuiOutlinedInput-notchedOutline {
            border-color: var(--accent-color);
            border-width: 2px;
          }
        }
      }

      .MuiOutlinedInput-input {
        color: var(--text-primary);
        padding: 0.75rem 1rem;

        &::placeholder {
          color: var(--text-secondary);
          opacity: 0.8;
        }
      }

      .MuiInputLabel-root {
        color: var(--text-secondary);

        &.Mui-focused {
          color: var(--accent-color);
        }
      }

      .MuiOutlinedInput-notchedOutline {
        border-color: var(--border-color);
      }
    }
  }

  .buttonGroup {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    margin-top: 2rem;
    width: 100%;

    @media (max-width: 480px) {
      flex-direction: column;
      gap: 0.75rem;

      > div {
        width: 100%;
        
        &:last-child {
          display: flex;
          gap: 0.75rem;

          button {
            flex: 1;
          }
        }
      }
    }

    button {
      min-width: 120px;
      padding: 0.875rem 1.5rem;
      border-radius: 8px;
      font-size: 0.9375rem;
      font-weight: 500;
      transition: all 0.2s ease;
      border: none;
      cursor: pointer;

      @media (max-width: 480px) {
        width: 100%;
        padding: 0.75rem 1rem;
      }

      &.saveButton {
        background-color: var(--accent-color);
        color: white;

        &:hover {
          background-color: var(--accent-light);
        }

        &:active {
          transform: translateY(1px);
        }
      }

      &.cancelButton {
        background-color: var(--bg-tertiary);
        color: var(--text-secondary);

        &:hover {
          background-color: var(--bg-secondary);
          color: var(--text-primary);
        }
      }

      &.deleteButton {
        background-color: rgba(239, 68, 68, 0.1);
        color: #ef4444;

        &:hover {
          background-color: #ef4444;
          color: white;
        }

        &:active {
          transform: translateY(1px);
        }
      }
    }
  }
}

// Scoped styles for input and selector fields
:global {
  .inputField {
    margin-bottom: 1rem;
    max-width: 400px;

    .label {
      font-size: 0.875rem;
      font-weight: 500;
      color: var(--text-secondary);
      margin-bottom: 0.5rem;
    }

    .input {
      padding: 0.75rem 1rem;
      background: var(--bg-tertiary);
      border: 1px solid var(--border-color);
      border-radius: 8px;
      font-size: 0.9375rem;
      color: var(--text-primary);
      transition: all var(--transition-speed) ease;

      &::placeholder {
        color: var(--text-secondary);
        opacity: 0.7;
      }

      &:hover {
        border-color: var(--accent-color);
        background: var(--bg-tertiary);
      }

      &:focus {
        outline: none;
        border-color: var(--accent-color);
        background: var(--bg-tertiary);
        box-shadow: 0 0 0 3px rgba(99, 102, 241, 0.1);
      }
    }
  }

  .selectorField {
    margin-bottom: 1rem;

    .label {
      font-size: 0.875rem;
      font-weight: 500;
      color: var(--text-secondary);
      margin-bottom: 0.5rem;
    }

    .selector {
      .selector-field__control {
        min-height: 42px;
        background: var(--bg-tertiary);
        border: 1px solid var(--border-color);
        border-radius: 8px;
        transition: all var(--transition-speed) ease;

        &:hover {
          border-color: var(--accent-color);
          background: var(--bg-tertiary);
        }

        &.selector-field__control--is-focused {
          border-color: var(--accent-color);
          background: var(--bg-tertiary);
          box-shadow: 0 0 0 3px rgba(99, 102, 241, 0.1);
        }
      }

      .selector-field__menu {
        background: var(--bg-secondary);
        border: 1px solid var(--border-color);
        box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
        margin-top: 4px;
        border-radius: 8px;
        z-index: 2;

        .selector-field__menu-list {
          padding: 4px;
        }

        .selector-field__option {
          background: transparent;
          color: var(--text-primary);
          border-radius: 6px;
          padding: 8px 12px;
          cursor: pointer;

          &--is-focused {
            background: var(--bg-tertiary);
          }

          &--is-selected {
            background: var(--accent-color);
            color: white;
          }
        }
      }

      .selector-field__value-container {
        padding: 2px 8px;
      }

      .selector-field__single-value {
        color: var(--text-primary);
      }

      .selector-field__placeholder {
        color: var(--text-secondary);
        opacity: 0.7;
      }
    }
  }
}
