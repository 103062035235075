.modal {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 100px;
  left: 0;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 1rem;

  .innerModal {
    width: 500px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #424752fa;
    border-radius: 5px;
    padding: 2.5rem 1rem;

    .counter {
      position: absolute;
      top: 5px;
      left: 5px;
      color: #61dafb;
      font-size: 12px;
      border-radius: 4px;
      padding: 5px 10px;
      background-color: #3c3c3c;
    }

    .closeIcon,
    .editIcon,
    .deleteIcon {
      position: absolute;
      cursor: pointer;
      height: 15px;
      width: 15px;
      padding: 7px;
      color: #61dafb;
      top: 5px;
      right: 5px; 
      border-radius: 10px;
      background-color: #3c3c3c;
    }

    .editIcon {
      right: 45px; 
    }

    .deleteIcon {
      right: 85px; 
    }
  }
}