.suggestions {
  display: flex;
  flex-direction: column;
  border: 2px solid var(--border-color, rgba(0, 0, 0, 0.2));
  background-color: var(--bg-secondary, #1c1f24);
  overflow: hidden;
  transition: all ease 0.3s;
  height: 0;
  opacity: 0;
  width: 100%;
  position: absolute;
  top: calc(100% + 4px);
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 1000;
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);

  @media (max-width: 768px) {
    max-height: 60vh;
    width: calc(100% - 2rem);
  }

  &.active {
    height: auto;
    max-height: 300px;
    opacity: 1;
  }

  .loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
    width: 100%;
  }

  .appComponent {
    display: flex;
    align-items: center;
    text-align: left;
    padding: 0.75rem 1rem;
    border-top: 1px solid var(--border-color, rgba(0, 0, 0, 0.2));
    justify-content: space-between;
    cursor: pointer;
    transition: background-color 0.2s ease;

    &:first-child {
      border-top: none;
    }

    &:hover {
      background-color: var(--hover-bg, rgba(255, 255, 255, 0.05));
    }

    &:active {
      background-color: var(--active-bg, rgba(255, 255, 255, 0.1));
    }

    .dataBox {
      flex: 1;
      min-width: 0;
      margin-right: 1rem;

      .name {
        font-size: 1rem;
        font-weight: 500;
        color: var(--text-primary);
        margin-bottom: 0.25rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .description {
        font-size: 0.875rem;
        color: var(--text-secondary);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .actionButton {
      padding: 0.5rem 1rem;
      border-radius: 8px;
      border: 1px solid var(--border-color);
      background: transparent;
      color: var(--text-primary);
      font-size: 0.875rem;
      cursor: pointer;
      transition: all 0.2s ease;
      white-space: nowrap;

      &:hover {
        background: var(--hover-bg);
        border-color: var(--text-primary);
      }
    }
  }

  .noResults {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2rem;
    text-align: center;
    color: var(--text-secondary);
    
    .icon {
      font-size: 2rem;
      margin-bottom: 1rem;
    }
    
    .message {
      font-size: 0.875rem;
    }
  }
}
