.userPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 800px;
  margin: 0 auto 2rem;
  padding: 0 1rem;

  h4 {
    width: 100%;
    font-size: 1.25rem;
    color: #90cdf4;
    margin: 2rem 0 1rem;
    padding-bottom: 0.5rem;
    border-bottom: 2px solid #2d3748;
  }

  .cta {
    background-color: #4299e1;
    color: white;
    border: none;
    padding: 0.75rem 1.5rem;
    border-radius: 8px;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.2s ease;
    margin-bottom: 1rem;
    font-size: 1rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

    &:hover {
      background-color: #3182ce;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.15);
    }

    &:active {
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    }
  }

  .box {
    background-color: #1a202c;
    border-radius: 12px;
    padding: 1.5rem;
    margin-bottom: 2rem;
    color: #e2e8f0;
    width: 100%;
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.2);
    transition: box-shadow 0.3s ease;

    &:hover {
      box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3);
    }

    .userInfo {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0.75rem 0;
      border-bottom: 1px solid #2d3748;

      &:last-child {
        border-bottom: none;
      }

      .label {
        font-weight: 600;
        color: #90cdf4;
        flex: 0 0 150px;
      }

      .value {
        flex: 1;
        text-align: right;
        overflow-wrap: break-word;
        word-wrap: break-word;
        color: #e2e8f0;

        svg {
          width: 20px;
          height: 20px;
          vertical-align: middle;

          &:first-child {
            color: #48bb78;
          }

          &:last-child {
            color: #f56565;
          }
        }
      }
    }
  }
}
