html, .App {
  height: 100%;
}

.App {
  text-align: center;
  background-color: #282c34;
  min-height: 100vh;
  color: white;
  overflow: hidden;
  scroll-behavior: smooth;
}

.ctaBox {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.cta {
  margin: 0.25rem;
  cursor: pointer;
  font-size: 14px;
  color: #c9c9c9;
  border-radius: 5px;
  padding: 0.5rem 0.75rem;
  background-color: #61dafb38;
  border: 1px solid #61dafb;
}

.inputWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 350px;

  .splitInput {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;

    .inputField {
      width: 48%;
    }
  }
}

.addIcon {
  height: 25px;
  width: 25px;
  padding: 10px;
  cursor: pointer;
  color: #61dafb;
  border-radius: 50px;
  background-color: #1d2026;

  &.close {
    margin-left: 0.5rem;
    height: 20px;
    width: 20px;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
