.checkboxField {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin: 0.5rem 0;
  width: 100%;

  .label {
    color: #fff;
    font-size: 16px;
  }

  .checkbox {
    margin-right: 0.5rem;
  }
}