@import '../../styles/variables.scss';

.subscriptionsHeader {
  background-color: #0000003b;
  border-radius: 3px;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid;

  .title {
    margin: 0;
  }

  .total {
    font-size: 18px;
    display: flex;
    justify-content: center;

    .spacer {
      margin: 0 0.25rem;
    }
  }
}

.currency {
  display: flex;

  @include devices(mobile) {
    flex-direction: column;
  }

  > :not(:last-child) {
    margin-right: 0.5rem;
  }
}
