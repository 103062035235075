.listTable {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
  background-color: #1a202c;
  border-radius: 8px;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);

  .noLists {
    color: #a0aec0;
    text-align: center;
    padding: 2rem;
    font-style: italic;
  }

  .listPreview {
    background-color: #2d3748;
    border-radius: 6px;
    padding: 1rem;
    transition: all 0.2s ease-in-out;
    border: 1px solid #4a5568;

    &:hover {
      box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
      border-color: #90cdf4;
    }

    h3 {
      color: #90cdf4;
      margin: 0 0 0.5rem 0;
      font-size: 1.1rem;
    }

    p {
      color: #e2e8f0;
      margin: 0;
      font-size: 0.9rem;

      &.description {
        color: #a0aec0;
        margin-top: 0.5rem;
      }
    }

    .stats {
      display: flex;
      gap: 1rem;
      margin-top: 1rem;
      padding-top: 0.5rem;
      border-top: 1px solid #4a5568;

      span {
        color: #a0aec0;
        font-size: 0.875rem;

        strong {
          color: #90cdf4;
          font-weight: 600;
        }
      }
    }
  }
}
