.menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  background: rgba(18, 18, 18, 0.8);
  backdrop-filter: blur(10px);
  position: sticky;
  top: 0;
  z-index: 1000;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);

  .logoBox {
    justify-content: flex-start;
    display: flex;
    flex: 1;

    .logo {
      transition: opacity 0.2s ease;

      &:hover {
        opacity: 0.8;
      }

      .image {
        height: 40px;
        width: auto;
        color: #fff;

        .logo-text {
          font-weight: 500;
          letter-spacing: 0.5px;
        }

        @media (max-width: 768px) {
          height: 32px;
        }
      }
    }
  }

  .widgetBox {
    justify-content: flex-end;
    display: flex;
    align-items: center;
    gap: 0.5rem;

    .widget-container {
      padding: 0.5rem;
      border-radius: 8px;
      transition: all 0.2s ease;
      position: relative;

      &:hover {
        background: rgba(255, 255, 255, 0.1);
      }

      .widget {
        cursor: pointer;
        height: 22px;
        width: auto;
        color: #fff;
        opacity: 0.8;
        transition: opacity 0.2s ease;

        &:hover {
          opacity: 1;
        }
      }
    }

    @media (max-width: 768px) {
      gap: 0.25rem;

      .widget-container {
        padding: 0.4rem;

        .widget {
          height: 20px;
        }
      }
    }
  }
}
