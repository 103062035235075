.newListModal {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  padding: 2rem 0;

  .appImage {
    height: 75px;
    margin-right: 1rem;
    border-radius: 5px;
  }

  .title {
    font-size: 22px;
    margin: 0;
    padding-bottom: 0.25rem;  
  }

  .description {
    width: 500px;
    font-size: 12px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
}