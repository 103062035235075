.appPlatforms {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;

  h3 {
    font-size: 1.125rem;
    font-weight: 600;
    color: #e1e1e1;
    margin: 1.5rem 0 1rem;
    text-align: center;
  }

  .appPlatformsWrapper {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(400px, 400px));
    gap: 1rem;
    width: 100%;
    justify-content: center;
  }

  .platformOuterBox {
    background: rgba(255, 255, 255, 0.03);
    border: 1px solid rgba(255, 255, 255, 0.05);
    border-radius: 12px;
    padding: 1rem;
    transition: all 0.2s ease;

    &:hover {
      background: rgba(255, 255, 255, 0.05);
      border-color: rgba(255, 255, 255, 0.1);
    }

    .appPlatformBoxWrapper {
      text-decoration: none;
      color: #e1e1e1;

      .appPlatformBox {
        display: flex;
        align-items: center;
        gap: 0.75rem;
        margin-bottom: 1rem;

        .platformLogo {
          width: 32px;
          height: 32px;
          padding: 6px;
          border-radius: 8px;
          background: rgba(255, 255, 255, 0.05);
          border: 1px solid rgba(255, 255, 255, 0.08);
        }
      }
    }

    .appPlatformLinkBox {
      display: flex;
      align-items: center;
      gap: 0.75rem;
      padding: 0.75rem;
      background: rgba(255, 255, 255, 0.03);
      border-radius: 8px;
      border: 1px solid rgba(255, 255, 255, 0.08);
      margin-bottom: 1rem;

      .appPlatformLinkText {
        font-family: 'SF Mono', 'Roboto Mono', monospace;
        font-size: 0.8125rem;
        color: #b0b0b0;
        margin: 0;
        flex-grow: 1;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .linkCopyIcon {
        width: 16px;
        height: 16px;
        color: #64b5f6;
        cursor: pointer;
        padding: 0.4rem;
        border-radius: 6px;
        transition: all 0.2s ease;
        background: rgba(33, 150, 243, 0.1);
        flex-shrink: 0;

        &:hover {
          background: rgba(33, 150, 243, 0.15);
          transform: translateY(-1px);
        }

        &:active {
          transform: translateY(0);
        }
      }
    }

    .platform-item {
      display: flex;
      align-items: center;
      gap: 1rem;
      width: 100%;

      .platform-icon {
        width: 32px;
        height: 32px;
        padding: 6px;
        border-radius: 8px;
        background: rgba(255, 255, 255, 0.05);
        border: 1px solid rgba(255, 255, 255, 0.08);
        flex-shrink: 0;
      }

      .link-box {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        flex: 1;
        padding: 0.5rem 0.75rem;
        background: rgba(255, 255, 255, 0.03);
        border-radius: 8px;
        border: 1px solid rgba(255, 255, 255, 0.08);
        min-width: 0;

        .platform-url {
          font-family: 'SF Mono', 'Roboto Mono', monospace;
          font-size: 0.8125rem;
          color: #b0b0b0;
          background: transparent;
          border: none;
          outline: none;
          width: 100%;
          padding: 0;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;

          &:focus {
            color: #e1e1e1;
            white-space: normal;
            word-break: break-all;
          }
        }

        .copy-icon {
          width: 16px;
          height: 16px;
          color: #64b5f6;
          cursor: pointer;
          padding: 0.4rem;
          border-radius: 6px;
          transition: all 0.2s ease;
          background: rgba(33, 150, 243, 0.1);
          flex-shrink: 0;

          &:hover {
            background: rgba(33, 150, 243, 0.15);
            transform: translateY(-1px);
          }

          &:active {
            transform: translateY(0);
          }
        }
      }

      .delete-button {
        width: 32px;
        height: 32px;
        padding: 6px;
        color: #ef5350;
        cursor: pointer;
        border-radius: 8px;
        background: rgba(244, 67, 54, 0.1);
        transition: all 0.2s ease;
        flex-shrink: 0;

        &:hover {
          background: rgba(244, 67, 54, 0.15);
          transform: translateY(-1px);
        }

        &:active {
          transform: translateY(0);
        }
      }

      .appPlatformStatusBox {
        display: flex;
        gap: 0.5rem;

        .statusButton {
          width: 32px;
          height: 32px;
          padding: 6px;
          cursor: pointer;
          border-radius: 8px;
          transition: all 0.2s ease;

          &.approve {
            color: #66bb6a;
            background: rgba(76, 175, 80, 0.1);

            &:hover {
              background: rgba(76, 175, 80, 0.15);
              transform: translateY(-1px);
            }
          }

          &.reject {
            color: #ef5350;
            background: rgba(244, 67, 54, 0.1);

            &:hover {
              background: rgba(244, 67, 54, 0.15);
              transform: translateY(-1px);
            }
          }

          &:active {
            transform: translateY(0);
          }
        }
      }
    }
  }
}
