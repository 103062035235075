.subscription {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: var(--bg-secondary);
  border: 1px solid var(--border-color);
  border-radius: 16px;
  padding: 1.75rem;
  transition: all var(--transition-speed) ease;
  overflow: hidden;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.12);
    border-color: var(--accent-color);

    .editButton {
      opacity: 1;
      transform: translateY(0);
    }
  }

  .subscriptionWrap {
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
  }

  .block {
    display: flex;
    align-items: center;
    gap: 1.25rem;
    width: 100%;

    p {
      font-size: 1rem;
      font-weight: 500;
      color: var(--text-primary);
      margin: 0;
      flex: 1;
      line-height: 1.5;
    }
  }

  .appIcon {
    width: 56px;
    height: 56px;
    border-radius: 14px;
    object-fit: cover;
    background-color: var(--bg-tertiary);
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
  }

  .price {
    display: inline-flex;
    align-items: center;
    font-size: 0.9375rem;
    font-weight: 600;
    color: var(--accent-light);
    background-color: rgba(99, 102, 241, 0.1);
    padding: 0.625rem 1.25rem;
    border-radius: 24px;
    border: 1px solid rgba(99, 102, 241, 0.2);
    margin: 0;
    box-shadow: 0 1px 2px rgba(99, 102, 241, 0.05);
  }

  .editButton {
    position: absolute;
    top: 1rem;
    right: 1rem;
    opacity: 0;
    transform: translateY(-4px);
    transition: all var(--transition-speed) ease;
    
    button {
      width: 40px;
      height: 40px;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: var(--bg-tertiary);
      border: 1px solid var(--border-color);
      border-radius: 12px;
      color: var(--text-secondary);
      cursor: pointer;
      transition: all var(--transition-speed) ease;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);

      &:hover {
        background-color: var(--accent-color);
        color: var(--text-primary);
        border-color: var(--accent-light);
        box-shadow: 0 4px 12px rgba(99, 102, 241, 0.2);
      }

      svg {
        width: 18px;
        height: 18px;
      }
    }
  }

  .note {
    margin-top: 1.25rem;
    padding-top: 1.25rem;
    border-top: 1px solid var(--border-color);
    color: var(--text-secondary);
    font-size: 0.9375rem;
    line-height: 1.6;
  }

  hr {
    width: 100%;
    border: none;
    border-top: 1px solid var(--border-color);
    margin: 1.25rem 0;
    opacity: 0.8;
  }
}
