:root {
  /* Light mode variables */
  --background-primary: #18181b;
  --background-secondary: #1f1f23;
  --input-background: #27272a;
  --text-primary: #f4f4f5;
  --text-secondary: #a1a1aa;
  --border-color: #3f3f46;
  --button-background: #2196f3;
  --button-text: #ffffff;
  --button-hover: #1976d2;
  --overlay-color: rgba(0, 0, 0, 0.4);
  --focus-ring: rgba(33, 150, 243, 0.2);
  --link-color: #2196f3;
  --placeholder-color: #6b7280;
  --input-border: rgba(75, 85, 99, 0.8);
}

@media (prefers-color-scheme: dark) {
  :root {
    --background-primary: #18181b;
    --background-secondary: #27272a;
    --text-primary: #f4f4f5;
    --text-secondary: #a1a1aa;
    --border-color: #3f3f46;
    --input-background: #27272a;
    --button-background: #2196f3;
    --button-text: #ffffff;
    --button-hover: #1976d2;
    --overlay-color: rgba(0, 0, 0, 0.6);
    --focus-ring: rgba(33, 150, 243, 0.2);
    --link-color: #2196f3;
  }
}

.loginPage {
  min-height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--background-primary);
  padding: 16px;
  box-sizing: border-box;

  .loginContent {
    width: 100%;
    max-width: 400px;
    background: var(--background-secondary);
    border-radius: 16px;
    padding: 32px 24px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 32px;
    box-sizing: border-box;
  }

  .header {
    text-align: center;

    .welcomeText {
      font-size: 30px;
      font-weight: 600;
      color: var(--text-primary);
      margin-bottom: 8px;
    }

    .subtitle {
      font-size: 16px;
      color: var(--text-secondary);
    }
  }

  .loginForm {
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;

    .formFields {
      display: flex;
      flex-direction: column;
      gap: 20px;
    }

    .field {
      display: flex;
      flex-direction: column;
      gap: 8px;

      label {
        font-size: 14px;
        font-weight: 500;
        color: var(--text-primary);
        text-align: left;
      }

      .inputContainer {
        position: relative;
        width: 100%;

        input {
          width: 100%;
          height: 44px;
          padding: 0 16px;
          background: var(--input-background);
          border: 1px solid var(--input-border);
          border-radius: 8px;
          font-size: 15px;
          color: var(--text-primary);
          box-sizing: border-box;
          transition: all 0.2s ease;

          &::placeholder {
            color: var(--placeholder-color);
          }

          &:hover {
            border-color: var(--text-secondary);
          }

          &:focus {
            outline: none;
            border-color: var(--link-color);
            box-shadow: 0 0 0 1px var(--link-color);
          }
        }

        .passwordToggle {
          position: absolute;
          right: 16px;
          top: 50%;
          transform: translateY(-50%);
          color: var(--text-secondary);
          cursor: pointer;
        }
      }
    }

    .forgotPassword {
      font-size: 14px;
      color: var(--link-color);
      text-decoration: none;
      margin-top: -8px;
      text-align: left;
      &:hover {
        text-decoration: underline;
      }
    }

    .loginButton {
      width: 100%;
      height: 44px;
      background: var(--button-background);
      color: var(--button-text);
      border: none;
      border-radius: 8px;
      font-size: 15px;
      font-weight: 500;
      cursor: pointer;
      transition: background-color 0.2s ease;

      &:hover {
        background: var(--button-hover);
      }
    }

    .createAccount {
      text-align: center;
      font-size: 14px;
      color: var(--text-secondary);

      a {
        color: var(--link-color);
        text-decoration: none;
        margin-left: 4px;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  @media (max-width: 1023px) {
    .loginSide {
      width: 100%;
    }
  }

  @media (prefers-reduced-motion: reduce) {
    *,
    *::before {
      transition: none !important;
      animation: none !important;
    }
  }
}
