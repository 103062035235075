.listPage {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  margin: 0.5rem;

  .counter {
    text-align: left;
  }
  
  .searchBox {
    display: flex;
    margin-top: 1rem;

    .searchField {
      font-size: 18px;
      color: #c9c9c9;
      border: 1px solid #61dafb;
      background-color: #ffffff26;
      border-radius: 3px;
      padding: 0.5rem;
    }

    .suggestions.active {
      top: 480px;
    }
  }
}
