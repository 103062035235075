.profilePage {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  
  .AppLogo {
    height: 10rem;
    pointer-events: none;
    
    @media (prefers-reduced-motion: no-preference) {
      animation: App-logo-spin infinite 20s linear;
    }
  }
  
  .title {
    font-size: 24px;
  }

  .searchField {
    font-size: 18px;
    color: #c9c9c9;
    border: 1px solid #61dafb;
    background-color: #ffffff26;
    border-radius: 3px;
    padding: 0.5rem;
  }

  .ctaBox {
    justify-content: center;
    margin: 0.5rem;
  }
}