.featuredListsPage {
  .searchWrapper {
    width: 500px;
    margin: 0 auto;
    position: relative;

    .searchField {
      font-size: 18px;
      color: #c9c9c9;
      border: 1px solid #61dafb;
      background-color: #ffffff26;
      border-radius: 3px;
      padding: 0.5rem;
      width: 100%;
      margin-bottom: 8px;
    }
  }

  .title {
    font-size: 28px;
  }

  .listBox {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    align-items: center;
    max-width: 900px;
    margin: 0 auto;

    .listWrapper {
      width: 50%;
      position: relative;
    }
  }
}
